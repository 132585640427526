import React from "react";
// import Button from "react-bootstrap/Button";
// import Navbar from "react-bootstrap/Navbar";

// import Nav from "react-bootstrap/Nav";
// import { NavLink } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";

const Navigation = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarMenu"
        aria-controls="navbarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarMenu">
        <a className="navbar-brand" href="/">
          <img alt="Brand" src="/images/s&c.gif" width="30"></img>
        </a>
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          <li className="nav-item active">
            <a className="nav-link" href="/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/east">
              From The East
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="/secretary">
              From The Secretary
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="/masoniceducation">
              Masonic Education
            </a>
          </li> */}

          <li className="nav-item">
            <a className="nav-link" href="/officers">
              Officers
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/history">
              History
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pastmasters">
              Past Masters
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/district">
              District
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/links">
              Links
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contacts">
              Contacts
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
