import React from "react";
import { IOfficer } from "../../data/IOfficer";
import officers from "../../data/officers";

const Officers = () => {
  const officerList: IOfficer[] = officers;

  return (
    <>
      <div className="card">
        <div className="card-header">2021 Officers</div>
        <div className="card-body">
          <table className="table-striped table-bordered" cell-padding="5">
            <tbody>
              {officerList.map((officer, i) => {
                return (
                  <tr key={i}>
                    <td className="officer-title">{officer.title}</td>
                    <td className="officer-name">{officer.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Officers;
