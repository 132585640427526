import React from "react";
import { ILink } from "../../data/ILink";
import links from "../../data/links";

const Links = () => {
  const usefulLinks: ILink[] = links;

  return (
    <>
      <div className="card">
        <div className="card-header">Useful Links</div>
        <div className="card-body">
          <table className="table-striped table-bordered" cell-padding="5">
            <tbody>
              {usefulLinks.map((link, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <a href={link.url}>{link.name}</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Links;
