import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <p>"Meet Upon the Level and Part Upon the Square"</p>
        </div>
        <div className="card-body">
          <p>
            As the crisp autumn air settles in, this November we embrace the
            changing of the seasons. During this time, we find ourselves
            reflecting on the year's accomplishments. This provides a perfect
            for a time for fellowship and charity.
          </p>

          <p>
            Our first responder Breakfast was even better than last year. The
            word of this annual event has really gotten out. I give an extra
            thanks to the hard work and excellent cooking of our Brothers who
            volunteered this event.
          </p>

          <p>
            We had a decent turn out for the last Blood drive of this season. we
            collected 21 pints of blood.
          </p>

          <p>
            I am excited to announce we had a successful Brunswick stew
            fundraiser, an event that not only delighted your taste buds but
            also supported our Lodge's ongoing charitable initiatives. According
            to local tradition, Brunswick Virginia which was established in 1720
            from parts of Prince George, Isle of Wight, and Surry counties, the
            stew was created in 1828 by Jimmy Matthews, an African American
            hunting-camp cook. This beloved Southern dish, rich in flavor and
            tradition will be served out with a side of camaraderie and good
            cheer.
          </p>

          <p>
            We gather this November to honor a cherished tradition of our Lodge
            which is celebrating Past Masters Night. Our Past Masters have not
            only been the custodians of our traditions, but have also been
            guiding lights through challenges and changes. Their leadership has
            shaped the Lodge into the esteemed institution it is today.
          </p>

          <p>
            Past Masters Night is not just a time to celebrate the past, but
            also to renew our dedication to the future of our Lodge.
          </p>

          <p>
            As we enter this season of gratitude and Thanksgiving, lets us
            remember the principles of Masonry that guide us. Let us finish the
            month by celebrating Thanksgiving with family and friends.
          </p>
        </div>
        <div className="card-footer">
          <p>William "Bill" Coyner</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
