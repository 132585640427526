import React from "react";

const History = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          History of Prince George Masonic Lodge No. 115
        </div>
        <div className="card-body">
          <p>
            In the year 1854, a group of Master Masons living in the vicinity of
            Prince George Court House saw the need for a Masonic lodge. They
            proceeded to draw up a request for dispensation to meet and work as
            a Masonic body at Prince George Court House. After obtaining
            recommendations from Andrews Lodge No. 29, at City Point, Virginia,
            and Petersburg Lodge No. 15, Petersburg, Virginia, the Grand Master
            of Masons in Virginia granted the prayer of these brethren.
          </p>

          <p>
            The first meeting was held with dispensation at Prince George Court
            House on September 5th, 1854. At this meeting a committee was
            appointed to draw up the By-laws and report at the next meeting to
            be held on September 20th, 1854.
          </p>

          <h4>Disputanta Train Depot 1892-1894</h4>

          <p>
            <img
              src="/images/depot.gif"
              style={{
                marginRight: "10px",
                alignContent: "left",
                float: "left",
              }}
              alt={"Disputanta Train Depot"}
            />
            The committee having performed their duty, Prince George Lodge
            adopted its first set of By-laws. Immediately following the adoption
            of them the election of the first set of officers resulted in:
            Samuel H. Jones, Master; Robert Gilliam, Senior Warden; Theo. A.
            Hall, Junior Warden; S. Walsh, Secretary; William Jones, Treasurer;
            Nicholas Fenn, Senior Deacon; William Wells, Junior Deacon; Samuel
            Walsh, Chaplain; and William Bonner, Tyler.
          </p>

          <p>
            The passing of Thomas J. Young, and E.A. to F.C. was their next
            order of business and in due time Brother Young was, on December
            6th, 1854, the first o be made a Master Mason by Prince George
            Lodge.
          </p>

          <p>
            On December 6th, 1854, a motion was carried that the Master Prince
            George Lodge at the Grand Lodge of Virginia, and was instructed to
            try to obtain a Charter. This was done and December 13th, 1854, a
            Charter was issued to Prince George Lodge No. 115 A.F. & A.M. by the
            Grand Lodge of Virginia, and on January 3rd, 1855, Rt. Worshipful
            P.B. Starke, D.D.G.M. of District 5, installed the officers.
          </p>

          <p>
            The original membership came from two different lodges. Six members
            hailing from Andrews Lodge No. 29, City Point, Virginia, and five
            from Petersburg Lodge No. 15, Petersburg, Virginia. These eleven
            members were apparently correct in an endeavor to form their lodge
            for on April 15th, 1856, the Corner Stone was laid for a temple at
            Prince George Court House. It is assumed that the temple was
            completed at Prince George and that the lodge held its meeting in
            this temple for some 35 years. A petition for a new lodge was
            presented on September 26th, 1891, and this petition was voted on
            and passed November 28th, 1891, to move to Disputanta. Virginia, for
            twelve months. Then on February 25th, 1893, it was voted to remain
            at Disputanta, Virginia for another twelve months. It was on
            November 24th, 1894, that the motion was made to move back to Prince
            George Court House and this motion was carried on December 22nd,
            1894, and brother Wm. H. C. Spicer was appointed to move the
            furniture to Prince George for the January stated meeting. At this
            writing it has not been determined whether the lodge moved into the
            original building or some other structure. However, the lodge held
            its meeting at Prince George Court House for some thirteen years. It
            was on January 24th, 1907, that the membership voted to move back to
            Disputanta, Virginia, and held their next meeting on February 29th,
            1907, at Disputanta, Virginia. Further research might bring to light
            an interesting answer to the fate of the temple at Prince George
            Court House and also to the question as to how, when, and from what
            source the temple at Disputanta was obtained. It is conclusive that
            the Prince George Lodge owned their temple in Disputanta, Virginia,
            for on March 1st, 1928, the minutes record the purchase of the lodge
            by a Mr. L. Grammer for the price of $1200.00. There can also be
            found in the minutes recordings of having rented the lower floor of
            the temple at Prince George to the Bland Township for the purpose of
            holding a school. Conceivably, Prince George Lodge could have at one
            time owned two temples. However, there does not appear to be any
            record of any work being done at Prince George Court House after
            January 24th, 1907, except for a One Hundred Anniversary celebration
            which was held at Prince George High School on September 3rd, 1954.
          </p>

          <p>
            <img
              src="/images/disputanta.jpg"
              style={{ alignContent: "left", float: "left" }}
              alt={"Farmers Bank Building in Disputanta"}
            />
            Worshipful Albert Q. Livesay, on July 3rd. 1930, presented a
            resolution which was seconded by Wor. L. W. Morris to purchase the
            Farmers Bank Building at Disputanta, Virginia. This resolution
            passed and the Farmers Bank Building was purchased from the Bank of
            Southslde Virginia, Prince George, Virginia, and the lodge held its
            meeting in this building through March 2nd, 1978.
          </p>

          <i>Jordon E. Wood, Jr.</i>

          <h4>Updated History of Prince George Lodge No. 115</h4>

          <h5>1978-2004</h5>

          <p>
            I will attempt to bring the History of Prince George Lodge No 115 up
            to the present time. After the completion of the new building on
            State Road 644, they held their first meeting on April 6th, 1978.
            The Lodge has steadily progressed. The Lodge has been very fortunate
            with its leaders. There has been a steady line of young Masons going
            through the line. It is one of the fortunate Lodges that has not had
            to rely on its Past Masters. The Lodge has really matured. I will
            not mention any names because someone will be left out.
          </p>

          <p>
            There has been a lot of interest in masonry in this lodge from the
            Community. The membership has continued to grow very fast compared
            to the other Lodges in the District. I must mention the dedication
            of the Instructors, Stewards in the kitchen and the formation of the
            Craftsmen's Club. The Fundraisers they have every year, the
            involvement in the Community, the yearly Blood Drive, Scholarship
            Program, Annual Picnic, and the support of its widows, all
            contribute to the success of this Lodge. It is the dedication of
            these brethren that has helped in the growth of this Lodge. I
            congratulate all of the Worshipful Masters and members that have led
            this Lodge to its present level. I am very proud to say that Prince
            George Lodge No. 115 is one of the most active Lodges in the 29th
            Masonic District and one of the largest in membership. If Masonry is
            to survive, it is Lodges like Prince George No. 115 that will be
            carrying the banner.
          </p>

          <i>
            <p>Respectfully submitted,</p>
            <p>Joe Westfall</p>
            <p>Anniversary Committee Chairman</p>
          </i>

          <h4>Other Prince George County and 115 Lodges</h4>

          <p>
            The first lodge in Prince George County was Cabin Point Lodge No. 8,
            which was located at Cabin Point, Prince George County from April
            1775 until Dec.1848.
          </p>

          <p>
            The first No. 115 was Goochland Lodge No. 115, which met at
            Goochland Courthouse from Dec. 18, 1819 until December 1848.
          </p>

          <p>
            Prince George Lodge No. 148 met at City Point from December 1827
            until December 1851.
          </p>

          <p>
            Brandon Lodge No. 192 located at Brandon Church, Prince George
            County from December 1875 until February 1945.
          </p>
        </div>
      </div>
    </>
  );
};

export default History;
