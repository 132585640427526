import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./pages/Home/Home";
import Navigation from "./pages/Navigation/Navigation";
//import Secretary from "./pages/Secretary/Secretary";
import East from "./pages/East/East";
import Officers from "./pages/Officers/Officers";
import History from "./pages/History/History";
import PastMasters from "./pages/PastMasters/PastMasters";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faCog, faCheck } from "@fortawesome/free-solid-svg-icons";
import District from "./pages/District/District";
import Links from "./pages/Links/Links";
import Contacts from "./pages/Contacts/Contacts";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

function App() {
  library.add(faPlus, faCog, faCheck);

  return (
    <>
      <div className="container body-content">
        <Navigation />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/east" element={<East />} />
            {/* <Route path="/secretary" element={<Secretary />} /> */}
            {/* <Route path="/masoniceducation" element={<Secretary />} /> */}
            <Route path="/officers" element={<Officers />} />
            <Route path="/history" element={<History />} />
            <Route path="/pastmasters" element={<PastMasters />} />
            <Route path="/district" element={<District />} />
            <Route path="/links" element={<Links />} />
            <Route path="/contacts" element={<Contacts />} />
          </Routes>
        </Router>
        <footer>
          <p>&copy; {new Date().getFullYear()} - Prince George Lodge No. 115</p>
        </footer>
      </div>
    </>
  );
}

export default App;
