import React from "react";
import { ILodge } from "../../data/ILodge";
import districtLodges from "../../data/districtLodges";

const District = () => {
  const lodges: ILodge[] = districtLodges;
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>District Information</p>
        </div>
        <div className="card-body">
          <table className="table-striped table-bordered" cell-padding="5">
            <thead>
              <tr>
                <td>
                  <strong>Name</strong>
                </td>
                <td>
                  <strong>Stated</strong>
                </td>
                <td>
                  <strong>Location</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {lodges.map((lodge, i) => {
                return (
                  <tr key={i}>
                    <td>{lodge.name}</td>
                    <td>{lodge.stated}</td>
                    <td>
                      <p>{lodge.place}</p>
                      <p>{lodge.street}</p>
                      <p className="lodge-city">{lodge.city}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  );
};

export default District;
