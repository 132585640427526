const districtLodges = [
  {
    name: "Blandford No. 3",
    stated: "2nd Tuesday, 7:30 p.m.",
    place: "Petersburg Temple",
    street: "115 S. Sycamore St.",
    city: "Petersburg, VA",
  },
  {
    name: "Petersburg No. 15",
    stated: "3rd Saturday, 9 a.m.",
    place: "Petersburg Temple",
    street: "115 S. Sycamore St.",
    city: "Petersburg, VA",
  },
  {
    name: "Prince George No. 115",
    stated: "1st Thursday, 7:30 p.m.",
    place: "Prince George Temple",
    street: "5709 Ruffin Road",
    city: "Prince George, VA",
  },
  {
    name: "Dinwiddie No. 136",
    stated: "2nd Monday, 7:30 p.m.",
    place: "Dinwiddie Temple",
    street: "U.S. Highway 1",
    city: "Dinwiddie, VA",
  },
  {
    name: "Virginia Lodge No. 177",
    stated: "3rd Tuesday, 7:30 p.m.",
    place: "Virginia Lodge No. 177",
    street: "77 Bailey Avenue",
    city: "Claremont, VA. 23899",
  },
  {
    name: "Dupont No. 289",
    stated: "3rd Monday, 7:30 p.m.",
    place: "Hopewell Temple",
    street: "730 W. Broadway",
    city: "Hopewell, VA",
  },
  {
    name: "Edwards No. 308",
    stated: "1st Tuesday, 7:30 p.m.",
    place: "Stony Creek Temple",
    street: "Main Street",
    city: "Stony Creek, VA",
  },
];
export default districtLodges;
