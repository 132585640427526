import React from "react";

const Contacts = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">To Contact Us</div>
        <div className="card-body">
          <table className="table-striped table-bordered" cell-padding="5">
            <tbody>
              <tr>
                <td>
                  <strong>By Mail</strong>
                </td>
                <td>
                  Prince George Lodge No. 115 A.F. & A.M.
                  <br />
                  5709 Ruffin Road
                  <br />
                  Prince George, VA 23875
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>By Email</strong>
                </td>
                <td>
                  <a href="mailto:pg115secretary@outlook.com">
                    Secretary (pg115secretary@outlook.com)
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Contacts;
