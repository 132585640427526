const affiliatedPastMasters = [
  {
    name: "George E. Berberi",
    lodge: "Chester #94",
    year: "2000",
    honorary: false,
    pastDDGM: false,
  },

  {
    name: "Richard K. Newman",
    lodge: "Dupont #289",
    year: "2016",
    pastDDGM: false,
    honorary: false,
  },
  {
    name: "John C. Livingston",
    lodge: "Chester #94",
    year: "1994",
    honorary: false,
    pastDDGM: false,
  },
  {
    name: "G. Barry Lodge",
    lodge: "Dupont #289",
    year: "1996, 1997",
    honorary: false,
    pastDDGM: true,
  },
  {
    name: "Donald H. Rackley",
    lodge: "Petersburg #15",
    year: "2019",
    honorary: false,
    pastDDGM: false,
  },
  {
    name: "Joseph P. Westfall, Jr.",
    lodge: "Powhatan Stark #124",
    year: "1971, 1985, 1996, 1997, 2005",
    honorary: true,
    pastDDGM: true,
  },
];
export default affiliatedPastMasters;
