import React from "react";
import { IAffiliatedPastMaster } from "../../data/IAffiliatedPastMaster";
import affiliatedPastMasters from "../../data/affiliatedPastMasters";
import pastMasters from "../../data/pastMasters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPastMaster } from "../../data/IPastMaster";

const PastMasters = () => {
  const masters: IPastMaster[] = pastMasters;
  const affiliated: IAffiliatedPastMaster[] = affiliatedPastMasters;

  return (
    <>
      <div className="card">
        <div className="card-header">Past Masters of Prince George No. 115</div>
        <div className="card-body">
          <img
            className="img-fluid"
            src="/images/past_masters_2019.jpg"
            alt="past masters 2019"
          />
          <p>
            <strong>
              The Past Masters present at the last Past Masters’ Night, Nov. 14,
              2019.
            </strong>
          </p>
          <table className="table-striped table-bordered" cell-padding="5">
            <thead>
              <tr>
                <td>
                  <strong>Year</strong>
                </td>
                <td>
                  <strong>Name</strong>
                </td>
                <td>
                  <strong>Deceased</strong>
                </td>
                <td>
                  <strong>PDDGM</strong>
                </td>
                <td>
                  <strong>Honorary</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {masters.map((pm, i) => {
                return (
                  <tr key={i}>
                    <td>{pm.year}</td>
                    <td>{pm.name}</td>
                    <td>
                      {pm.deceased === true ? (
                        <FontAwesomeIcon icon="plus" />
                      ) : (
                        <span></span>
                      )}
                    </td>
                    <td>
                      {pm.pastDDGM ? (
                        <FontAwesomeIcon icon="cog" />
                      ) : (
                        <span></span>
                      )}
                    </td>
                    <td>
                      {pm.honorary ? (
                        <FontAwesomeIcon icon="check" />
                      ) : (
                        <span></span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Affiliated Past Masters</div>
        <div className="card-body">
          <table className="table-striped table-bordered" cell-padding="5">
            <thead>
              <tr>
                <td>
                  <strong>Name</strong>
                </td>
                <td>
                  <strong>Lodge</strong>
                </td>
                <td>
                  <strong>Year</strong>
                </td>
                <td>
                  <strong>PDDGM</strong>
                </td>
                <td>
                  <strong>Honorary</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {affiliated.map((pm, i) => {
                return (
                  <tr key={i}>
                    <td>{pm.name}</td>
                    <td>{pm.lodge}</td>
                    <td>{pm.year}</td>
                    <td>
                      {pm.pastDDGM ? (
                        <FontAwesomeIcon icon="cog" />
                      ) : (
                        <span></span>
                      )}
                    </td>
                    <td>
                      {pm.honorary ? (
                        <FontAwesomeIcon icon="check" />
                      ) : (
                        <span></span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PastMasters;
