const links = [
  {
    name: "District 29 Web Site and Calendar",
    url: "https://29thmasonicdistrict.wixsite.com/afam/",
  },
  {
    name: "The Grand Lodge, A.F. & A.M., of Virginia",
    url: "https://grandlodgeofvirginia.org/",
  },
  {
    name: "Grand Chapter Royal Arch Masons in Virginia",
    url: "http://virginiaroyalarch.org/",
  },
  {
    name: "Grand Commandery, Knights Templar of Virginia",
    url: "http://www.vagckt.org/",
  },
  {
    name: "Scottish Rite, Southern Jurisdiction, USA",
    url: "https://scottishrite.org/",
  },
];
export default links;
