const officers = [
  { title: "Worshipful Master", name: "William F. Coyner" },
  { title: "Senior Warden", name: "Jack L. Brock, Jr." },
  { title: "Junior Warden", name: "Donald H. Rackley Jr." },
  { title: "Treasurer", name: "Roy B. Pugh, IV" },
  { title: "Secretary", name: "Bryan Scott Hammel" },
  { title: "Senior Deacon", name: "Michael R. Lilly" },
  { title: "Junior Deacon", name: "Larry C. Tate" },
  { title: "Chaplain", name: "James W. Tomko" },
  { title: "Tiler", name: "Roy B. Pugh III" },
];
export default officers;
